document.addEventListener('click', (evt) => {
  if (evt.target.classList.contains('delete-lot-btn')) {
    evt.target.closest('li').remove()
  }
})

const datepicker = document.querySelector('.rangepicker')
if (datepicker) {
  const rangepicker = new DateRangePicker(datepicker, {
    language: 'ru'
  })
}

const headerSearchInput = document.querySelector('.header-search-input')
const headerSearchBtns = document.querySelector('.search-btns')
headerSearchInput.addEventListener('input', (evt) => {
  evt.target.value !== '' ?  headerSearchBtns.classList.add('show-close-btn') : headerSearchBtns.classList.remove('show-close-btn')
})
